@import '../../../assets/styles/variables';

//home featured product
.featuredProducts {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: center;
	padding-bottom: 20px;
	padding-top: 0;
	margin-top: 14px;	
	background-color: #fff;

	@media all and ( max-width: 767px ) {
		padding-bottom: 0;
		margin-top: 0;
	}

	.imgWrapper {
		margin-top: 0;
	}
}

.featureProduct_wrapper {
	.featuredProducts {
		transition: all 0.3s linear;

		.btn-add-cart {
			max-width: inherit;
			padding: 3px 10px;
		}

		&:hover {
			box-shadow: 0 0 12px #ccc;

			@media all and ( min-width: 1025px ) {
				.wishlist {
					opacity: 1;
					transition: all 0.3s linear;
					background-color: #9b9ea0;
	
					.icon-heart-black {
						color: $white;
					}
	
					&.wishlist-added {
						opacity: 1;
						transition: all 0.3s linear;
						background-color: rgba( var(--primary-color), 1 );
	
						.icon-heart-black {
							color: $white;
						}
					}
				} 
			}
		}

		@media all and ( max-width: 1024px ) {
			.wishlist {
				opacity: 1;
				transition: all 0.3s linear;
				background-color: #9b9ea0;

				.icon-heart-black {
					color: $white;
				}

				&.wishlist-added {
					opacity: 1;
					transition: all 0.3s linear;
					background-color: rgba( var(--primary-color), 1 );

					.icon-heart-black {
						color: $white;
					}
				}
			} 
		}
	}	
}

.btn.btn-add-cart {
	@media all and ( min-width: 768px ) {
		opacity: 0;
	}
	font-weight: 300;
	padding: 3px;
}

.is-touch-device {
	.btn.btn-add-cart {
		opacity: 1;
	}
}

.pro {
	&Name {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.15;
		margin-bottom: 3px;
		padding-left: 8px;
		padding-right: 8px;
	}

	&Distance {
		background-color: transparent;
		border: 1px solid $black;
		color: $black;
		display: inline-block;
		padding: 2px 10px 2px 5px;
		border-radius: 20px;
		font-size: 11px;

		.icon {
			padding-right: 0;
			// color: rgba(var(--primary-color), 1);
		}
	}
}

.priceSec {
	font-size: 14px;
	font-weight: 300;
}

.regularprice {
	color: #9a9a9a;
	padding-right: 10px;
	text-decoration: line-through;
}

.saleprice {
	color: rgba(var(--secondary-color), 1);
	font-weight: 600;
}

.most-popular-product {
	padding-top: 30px;
	padding-bottom: 30px;

	@media all and ( max-width: 767px ) {
		padding-top: 10px;
		padding-bottom: 10px;

		.swiper-slide {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	a.link {
		color: $black;

		&:hover {
			text-decoration: none;
		}
	}

	.featuredProducts {
		.imgWrapper {
			height: 200px;
			margin-bottom: 10px;
			
		}

		.btn-add-cart {
			padding: 3px 10px;
			max-width: inherit;
			bottom: -10px;
		}

		.boxed {
			position: relative;
			padding-bottom: 20px;
			transition: all 0.3s linear;

			@media all and ( max-width: 767px ) {
				padding-bottom: 0;
			}
		}

		&:hover {
			.btn-add-cart {
				opacity: 1;	

				@media all and ( min-width: 1025px ) {
					transform: translateY(-15px);
				}
			}

			a {
				text-decoration: none;
			}
		}
	}
}

.swiper-slide {
	padding-top: 10px;
	padding-bottom: 10px;
	height: auto !important;
}
