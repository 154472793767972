@font-face {
	font-family: 'fontello';
	src: url('../font/fontello.eot?39126612');
	src: url('../font/fontello.eot?39126612#iefix') format('embedded-opentype'),
			 url('../font/fontello.woff2?39126612') format('woff2'),
			 url('../font/fontello.woff?39126612') format('woff'),
			 url('../font/fontello.ttf?39126612') format('truetype'),
			 url('../font/fontello.svg?39126612#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'fontello';
		src: url('../font/fontello.svg?39126612#fontello') format('svg');
	}
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "fontello", serif;
	font-style: normal;
	font-weight: normal;
	speak: none;
	font-display: swap;
 
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	/* opacity: .8; */
 
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
 
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
 
	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: .2em;
 
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
 
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
 
	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-bus:before { content: '\e800'; } /* '' */
.icon-truck:before { content: '\e801'; } /* '' */
.icon-search:before { content: '\e802'; } /* '' */
.icon-search-2:before { content: '\e803'; } /* '' */
.icon-add-plus:before { content: '\e804'; } /* '' */
.icon-mobile-app:before { content: '\e805'; } /* '' */
.icon-cart-add:before { content: '\e806'; } /* '' */
.icon-triangle:before { content: '\e807'; } /* '' */
.icon-settings:before { content: '\e808'; } /* '' */
.icon-box-1:before { content: '\e809'; } /* '' */
.icon-product:before { content: '\e80a'; } /* '' */
.icon-user-1:before { content: '\e80b'; } /* '' */
.icon-delete:before { content: '\e80c'; } /* '' */
.icon-calendar-alt-1:before { content: '\e80d'; } /* '' */
.icon-folder-1:before { content: '\e80e'; } /* '' */
.icon-folder-open-1:before { content: '\e80f'; } /* '' */
.icon-eye:before { content: '\e810'; } /* '' */
.icon-wishlist:before { content: '\e811'; } /* '' */
.icon-comments-1:before { content: '\e812'; } /* '' */
.icon-phone:before { content: '\e813'; } /* '' */
.icon-star-half-1:before { content: '\e814'; } /* '' */
.icon-edit-1:before { content: '\e815'; } /* '' */
.icon-edit:before { content: '\e816'; } /* '' */
.icon-picture:before { content: '\e817'; } /* '' */
.icon-tick:before { content: '\e818'; } /* '' */
.icon-check-mark:before { content: '\e819'; } /* '' */
.icon-invoice:before { content: '\e81a'; } /* '' */
.icon-maps-and-flags:before { content: '\e81b'; } /* '' */
.icon-pencil-striped-symbol-for-interface-edit-buttons:before { content: '\e81c'; } /* '' */
.icon-pencil-edit-button:before { content: '\e81d'; } /* '' */
.icon-cancel-1:before { content: '\e81e'; } /* '' */
.icon-cancel:before { content: '\e81f'; } /* '' */
.icon-cart-1:before { content: '\e820'; } /* '' */
.icon-rubbish:before { content: '\e821'; } /* '' */
.icon-thumbs-up-1:before { content: '\e822'; } /* '' */
.icon-thumbs-down-1:before { content: '\e823'; } /* '' */
.icon-envelop:before { content: '\e824'; } /* '' */
.icon-arrow-down:before { content: '\e825'; } /* '' */
.icon-arrow-right:before { content: '\e826'; } /* '' */
.icon-back:before { content: '\e827'; } /* '' */
.icon-next:before { content: '\e828'; } /* '' */
.icon-call-answer:before { content: '\e829'; } /* '' */
.icon-box:before { content: '\e82a'; } /* '' */
.icon-black-eye:before { content: '\e82b'; } /* '' */
.icon-eye-close-up:before { content: '\e82c'; } /* '' */
.icon-cart:before { content: '\e82d'; } /* '' */
.icon-testimonial:before { content: '\e82e'; } /* '' */
.icon-eye-1:before { content: '\e82f'; } /* '' */
.icon-heart-black:before { content: '\e830'; } /* '' */
.icon-heart:before { content: '\e831'; } /* '' */
.icon-dashboard:before { content: '\e832'; } /* '' */
.icon-mailing:before { content: '\e833'; } /* '' */
.icon-picture-1:before { content: '\e834'; } /* '' */
.icon-picture1:before { content: '\e835'; } /* '' */
.icon-right-arrow:before { content: '\e836'; } /* '' */
.icon-money:before { content: '\e837'; } /* '' */
.icon-facebook-f:before { content: '\ec5a'; } /* '' */
.icon-instagram:before { content: '\ec5b'; } /* '' */
.icon-linkedin-in:before { content: '\ec5d'; } /* '' */
.icon-twitter:before { content: '\ec5e'; } /* '' */
.icon-whatsapp:before { content: '\ec5f'; } /* '' */
.icon-youtube:before { content: '\ec60'; } /* '' */
.icon-search-1:before { content: '\f002'; } /* '' */
.icon-heart-1:before { content: '\f004'; } /* '' */
.icon-star:before { content: '\f005'; } /* '' */
.icon-user:before { content: '\f007'; } /* '' */
.icon-th-large:before { content: '\f009'; } /* '' */
.icon-th:before { content: '\f00a'; } /* '' */
.icon-th-list:before { content: '\f00b'; } /* '' */
.icon-check:before { content: '\f00c'; } /* '' */
.icon-times:before { content: '\f00d'; } /* '' */
.icon-search-plus:before { content: '\f00e'; } /* '' */
.icon-search-minus:before { content: '\f010'; } /* '' */
.icon-power-off:before { content: '\f011'; } /* '' */
.icon-signal:before { content: '\f012'; } /* '' */
.icon-home:before { content: '\f015'; } /* '' */
.icon-clock:before { content: '\f017'; } /* '' */
.icon-download:before { content: '\f019'; } /* '' */
.icon-inbox:before { content: '\f01c'; } /* '' */
.icon-redo:before { content: '\f01e'; } /* '' */
.icon-sync:before { content: '\f021'; } /* '' */
.icon-list-alt:before { content: '\f022'; } /* '' */
.icon-lock:before { content: '\f023'; } /* '' */
.icon-flag:before { content: '\f024'; } /* '' */
.icon-tags:before { content: '\f02c'; } /* '' */
.icon-bookmark:before { content: '\f02e'; } /* '' */
.icon-print:before { content: '\f02f'; } /* '' */
.icon-camera:before { content: '\f030'; } /* '' */
.icon-video:before { content: '\f03d'; } /* '' */
.icon-image:before { content: '\f03e'; } /* '' */
.icon-edit-2:before { content: '\f044'; } /* '' */
.icon-times-circle:before { content: '\f057'; } /* '' */
.icon-check-circle:before { content: '\f058'; } /* '' */
.icon-question-circle:before { content: '\f059'; } /* '' */
.icon-info-circle:before { content: '\f05a'; } /* '' */
.icon-eye-2:before { content: '\f06e'; } /* '' */
.icon-eye-slash:before { content: '\f070'; } /* '' */
.icon-calendar-alt:before { content: '\f073'; } /* '' */
.icon-comment:before { content: '\f075'; } /* '' */
.icon-shopping-cart:before { content: '\f07a'; } /* '' */
.icon-folder:before { content: '\f07b'; } /* '' */
.icon-folder-open:before { content: '\f07c'; } /* '' */
.icon-chart-bar:before { content: '\f080'; } /* '' */
.icon-cogs:before { content: '\f085'; } /* '' */
.icon-comments:before { content: '\f086'; } /* '' */
.icon-star-half:before { content: '\f089'; } /* '' */
.icon-thumbtack:before { content: '\f08d'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-hdd:before { content: '\f0a0'; } /* '' */
.icon-globe:before { content: '\f0ac'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-copy:before { content: '\f0c5'; } /* '' */
.icon-paperclip:before { content: '\f0c6'; } /* '' */
.icon-save:before { content: '\f0c7'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-envelope:before { content: '\f0e0'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-bell:before { content: '\f0f3'; } /* '' */
.icon-hospital:before { content: '\f0f8'; } /* '' */
.icon-plus-square:before { content: '\f0fe'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-calendar:before { content: '\f133'; } /* '' */
.icon-play-circle:before { content: '\f144'; } /* '' */
.icon-minus-square:before { content: '\f146'; } /* '' */
.icon-check-square:before { content: '\f14a'; } /* '' */
.icon-share-square:before { content: '\f14d'; } /* '' */
.icon-compass:before { content: '\f14e'; } /* '' */
.icon-caret-square-down:before { content: '\f150'; } /* '' */
.icon-caret-square-up:before { content: '\f151'; } /* '' */
.icon-caret-square-right:before { content: '\f152'; } /* '' */
.icon-file:before { content: '\f15b'; } /* '' */
.icon-file-alt:before { content: '\f15c'; } /* '' */
.icon-thumbs-up:before { content: '\f164'; } /* '' */
.icon-thumbs-down:before { content: '\f165'; } /* '' */
.icon-sun:before { content: '\f185'; } /* '' */
.icon-caret-square-left:before { content: '\f191'; } /* '' */
.icon-dot-circle:before { content: '\f192'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-cubes:before { content: '\f1b3'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-bell-slash:before { content: '\f1f6'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-sticky-note:before { content: '\f249'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-hourglass:before { content: '\f254'; } /* '' */
.icon-calendar-check:before { content: '\f274'; } /* '' */
.icon-map:before { content: '\f279'; } /* '' */
.icon-comment-alt:before { content: '\f27a'; } /* '' */
.icon-shopping-basket:before { content: '\f291'; } /* '' */
.icon-handshake:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-address-book:before { content: '\f2b9'; } /* '' */
.icon-address-card:before { content: '\f2bb'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-id-badge:before { content: '\f2c1'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-snowflake:before { content: '\f2dc'; } /* '' */
.icon-trash-alt:before { content: '\f2ed'; } /* '' */
.icon-images:before { content: '\f302'; } /* '' */
.icon-clipboard:before { content: '\f328'; } /* '' */
.icon-arrow-alt-circle-down:before { content: '\f358'; } /* '' */
.icon-arrow-alt-circle-left:before { content: '\f359'; } /* '' */
.icon-arrow-alt-circle-right:before { content: '\f35a'; } /* '' */
.icon-arrow-alt-circle-up:before { content: '\f35b'; } /* '' */
.icon-external-link-alt:before { content: '\f35d'; } /* '' */
.icon-cloud-download-alt:before { content: '\f381'; } /* '' */
.icon-cloud-upload-alt:before { content: '\f382'; } /* '' */
.icon-gem:before { content: '\f3a5'; } /* '' */
.icon-map-marker-alt:before { content: '\f3c5'; } /* '' */
.icon-mobile-alt:before { content: '\f3cd'; } /* '' */
.icon-tachometer-alt:before { content: '\f3fd'; } /* '' */
.icon-hockey-puck:before { content: '\f453'; } /* '' */
.icon-comment-dots:before { content: '\f4ad'; } /* '' */
.icon-coins:before { content: '\f51e'; } /* '' */
.icon-award:before { content: '\f559'; } /* '' */
.icon-medal:before { content: '\f5a2'; } /* '' */
.icon-meh-blank:before { content: '\f5a4'; } /* '' */
.icon-pen-fancy:before { content: '\f5ac'; } /* '' */
.icon-star-half-alt:before { content: '\f5c0'; } /* '' */
.icon-folder-minus:before { content: '\f65d'; } /* '' */
.icon-folder-plus:before { content: '\f65e'; } /* '' */
.icon-dice-d6:before { content: '\f6d1'; } /* '' */
.icon-border-all:before { content: '\f84c'; } /* '' */
.icon-sort-amount-down-alt:before { content: '\f884'; } /* '' */
.icon-sort-amount-up-alt:before { content: '\f885'; } /* '' */
