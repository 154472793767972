.featuredbrands {
	// display: flex;
	// justify-content: center;
	// align-items: center;

	img {
		// max-width: 110px;
	}

	.featuredbrandsImg {
		height: 100%;
		width: 100%;
		background-color: #F6F6F6;
		display: flex;
		justify-content: center;
		padding: 15px;

		@media all and ( max-width: 767px ) {
			background-color: #fff;
			border: 1px solid #eee;
		}

		.img-wrapper {
			background-color: #fff;
			display: flex;
    		align-items: center;
		}
	}

	@media all and ( max-width: 767px ) {
		&:nth-child( even ) {
			padding-left: 7px;
		}

		&:nth-child( odd ) {
			padding-right: 7px;
		}
	}
}

@media all and ( min-width: 576px ) and ( max-width: 767px ) {
	.featuredbrands {
		width: 25%;
		flex: 25%;
		max-width: 	25%;
	}
}
