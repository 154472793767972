@mixin m-mq( $breakpoint ) {
	@error "Use `@include media-breakpoint-down` instead.";
	@content;
}

@mixin poa( $display: block, $pos: absolute, $content: '' ) {
	content: $content;
	display: $display;
	position: $pos;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

@mixin backgrounds( $background-position: center, $background-repeat: no-repeat, $background-size: cover ) {
	background-repeat: $background-repeat;
	background-position: $background-position;
	background-size: $background-size;
}

@mixin padding( $padding-top: 20px, $padding-right: $padding-top, $padding-bottom: $padding-top, $padding-left: $padding-top ) {
	padding: $padding-top $padding-right $padding-bottom $padding-left;
}

@mixin padding-t-b( $padding-top: 20px, $padding-bottom: $padding-top ) {
	padding-top: $padding-top;
	padding-bottom: $padding-bottom;
}

@mixin padding-l-r( $padding-left: 20px, $padding-right: $padding-left ) {
	padding-left: $padding-left;
	padding-right: $padding-right;
}

@mixin transitions( $transition-time: 0.2s, $transition-ease: linear ) {
	transition: $transition-time $transition-ease;
}

@mixin fontello( $font-size: 10px, $color: $primary ) {
	font-family: fontello;
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	position: relative;
	top: 0;
	margin-right: 0;
	font-size: $font-size;
	color: $color;
	text-align: center;
	font-display: swap;
}

// https://dvcs.w3.org/hg/FXTF/raw-file/tip/filters/index.html 
//
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);

@mixin filter( $filter-type, $filter-amount ) {
	filter: $filter-type+unquote( '( #{$filter-amount} )' );
	transition: 0.3s linear;
}

// Mixins
// --------------------------

@mixin fa-icon() {
  display: inline-block;
  font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@mixin fa-icon-rotate($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}


// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

