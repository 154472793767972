#{ $all-text-inputs } {
	width: 100%;
	padding: 0 20px;
	font-size: 14px;
	color: inherit;
	background-color: $white;
	border-radius: 0;
	border: 1px solid rgba( $black, .5 );
	border: 0;

	transition: border-color .2s;
	box-shadow: none;

	&:focus {
		border-color: rgba( $black, .8 );
        box-shadow: none;
        outline: 0 !important;
	}

	&::placeholder {
		color: rgb(117, 117, 117);
		font-weight: 300;
		/*text-transform: uppercase;*/
		top: 0;
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

select {
	width: 100%;
	background-color: $white;
	padding: 0 $element-margin;
	height: $element-margin * 2.5;
	border-radius: 0;
	border: 1px solid rgba( $black, .1 );
	appearance: none;

	&:not( [multiple] ) {
		background-image: url( 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAABGdBTUEAALGPC/xhBQAAAG9JREFUKBWt0rsNgDAMBFCstGnjediMsBnzpHdtOCQkPvkYwkmWXPheZWLmqKrT8EOIaHYisnjvafPGHhNYSik6IL3ogcHawR70jF3AL+gde4Bv0ByWBS1oCSuCNbSGodcM/jSEoBjszYLlAJAVWwH660DHksNTUgAAAABJRU5ErkJggg==' );
		background-position: right $element-margin center;
		background-size: ( $element-margin / 2 ) ( $element-margin / 4 );
		background-repeat: no-repeat;
	}

	&[multiple] {
		padding-top: $element-margin;
		padding-bottom: $element-margin;
		height: 100px;
	}
}

[type='radio'],
[type='checkbox'] {
	position: absolute;
	z-index: -1;
	opacity: 0;

	&:checked + label:before {
		box-shadow: inset 0 0 0 18px $black;
	}

	+ label {
		padding-left: $element-margin * 1.5;
		position: relative;
		display: block;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 3px;
			left: 0;
			box-shadow: inset 0 0 0 1px $black;
			width: 18px;
			height: 18px;

			transition: box-shadow .2s;
		}
	}
}

[type='radio'] {
	&:checked + label:before {
		box-shadow: inset 0 0 0 6px $black;
	}

	+ label:before {
		border-radius: 100%;
	}
}

textarea {
	height: 200px;
	padding: 10px 20px;
	resize: vertical;
}

@media screen and ( -webkit-min-device-pixel-ratio: 0 ) and ( max-width: 767px ) {
	#{ $all-text-inputs },
	select {
		font-size: 16px;
	}
}