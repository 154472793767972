.btn {
	@include padding-t-b( 5px );

	display: inline-block;
	font-family: $font-family-sans-serif;
	font-size: 14px;
	font-weight: 700;
	text-decoration: none;
	word-wrap: break-word;
	white-space: normal;

	&:focus,
	&:hover {
		box-shadow: none !important;
        text-decoration: none;
        outline: 0;
	}

	&.btn-loading {
		min-height: 1.2em;
		position: relative;
		color: transparent !important;
		pointer-events: none;

		&::selection {
			background-color: transparent;
			color: transparent !important;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 1.2em;
			height: 1.2em;
			margin-left: -.6em;
			margin-top: -.6em;
			border-radius: 50%;
			border: 2px solid #fff;
			border-right-color: transparent;
			border-top-color: transparent;
			animation: rotate .5s infinite linear;
		}
	}
	
	&_categories {
		background: none;
        border: 0;
        
        &:focus {
            outline: 0;
        }
	}

	&-plus {
		height: 40px;
	}

	&-ht-30 {
		height: 30px;
		min-width: 110px;
		padding: 0;
	}

	&-medium {
		height: 40px;
		font-size: 16px;
		padding: 0 20px;
		min-width: 140px;
		line-height: 40px;
	}

	&-success {
		background-color: $green;
		border: 1px solid $green;

		&:disabled {
			pointer-events: none;
		}
	}

	&-primary {
		background-color: rgba(var(--secondary-color), 1);
		border-color: rgba(var(--secondary-color), 1);

		&:hover {
			background-color: rgba(var(--secondary-color), 1);
			border-color: rgba(var(--secondary-color), 0.8);
			box-shadow: inset 0 0 0 12em rgba(0, 0, 0, 0.3) !important;
		}

		&.btn-secondary {
			background-color: rgba(var(--primary-color), 1);
			border-color: rgba(var(--primary-color), 1);
		}
	}

	&-danger {
		background-color: rgba(var(--primary-color), 1);
		border-color: rgba(var(--primary-color), 1);

		&:hover,
		&:focus,
		&:active {
			background-color: rgba(var(--secondary-color), 0.9);
			border-color: rgba(var(--secondary-color), 0.9);
		}
	}

	&-outline-danger {
		color: rgba(var(--primary-color), 1);
		border-color: rgba(var(--primary-color), 1);

		&:hover,
		&:focus,
		&:active {
			background-color: rgba(var(--secondary-color), 1);
			border-color: rgba(var(--secondary-color), 1);
			color: $white;
		}
	}

	&.brRadius {
		&-20 {
			border-radius: 20px;
		}
	}

	&.btn-small-curve {
		padding: 5px 20px;
	}

	&Wrapper {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		.btn.btn-small-curve {
			padding: 5px 20px;
		}		
	}

	&.btn-add-cart {
		padding: 3px 10px;
		margin-top: 5px;
	}
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: rgba(var(--secondary-color), 1);
    border-color: rgba(var(--secondary-color), 1);
}

#mySinglebtn .btn-outline-danger:active {
	background-color: rgba(var(--secondary-color), 1);
	border-color: rgba(var(--secondary-color), 1);
	color: $white;	
}
