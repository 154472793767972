@import '../../../assets/styles/variables';

//home featured product
.featuredProducts {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: center;
	padding-bottom: 20px;
	position: relative;
	transition: all 0.3s linear;

	@media all and ( max-width: 767px ) {
		padding-bottom: 0;
		margin-top: 0;
	}

	.imgWrapper {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin-bottom: 8px;
	}

	.wishlist {
		position: absolute;
		width: 26px;
		height: 26px;
		top: 30px;
		right: 10px;
		left: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -15px;		
		margin-left: -15px;
		background-color: $white;
		text-align: center;
		border-radius: 3px;

		@media all and ( min-width: 1025px) {
			opacity: 0;
			background-color: $white;
		}
	}

	&.swiper-slide {
		&:hover {
			@media all and ( min-width: 1025px ) {
				.wishlist {
					opacity: 1;
					background-color: #9b9ea0;

					.icon-heart-black {
						color: $white;
					}

					&.wishlist-added {
						background-color: rgba(var(--primary-color), 1);
					}
				}
			}
		}

		@media all and ( max-width: 1024px ) {
			.wishlist {
				opacity: 1;
				background-color: #9b9ea0;

				.icon-heart-black {
					color: $white;
				}

				&.wishlist-added {
					background-color: rgba(var(--primary-color), 1);
				}
			}
		}
	}
}

.featureProduct_wrapper {
	.featuredProducts {
		&:hover {
			box-shadow: 0px 0px 12px #ccc;
			
			.btn-add-cart {
				@media all and ( min-width: 1025px ) {
					opacity: 1;
					max-width: inherit;
					padding: 3px 10px;
					transform: translateY(-15px);
				}
			}

			a {
				text-decoration: none;
			}
		}

		.btn-add-cart {
			max-width: inherit;
			padding: 3px 10px;
		}
	}

	.row {
		> div {
			margin-bottom: 20px;
		}
	}	
}

.categoryList.featuredProducts {
	&:hover {		
		.btn-add-cart {
			opacity: 1;
			max-width: inherit;
			padding: 3px 10px;
			margin-bottom: 0;

			@media all and ( min-width: 768px ) {
				transform: translateY(-15px);
				bottom: -10px;
			}
		}

		a {
			text-decoration: none;
		}

		.icon-heart-black {
			@media all and ( min-width: 768px ) {
				color: $white;
			}
		}

		.proDistance {
			color: rgba(var(--primary-color), 1);
			border-color: rgba(var(--primary-color), 1);
		}

		.wishlist {
			opacity: 1;
		}
	}

	.wishlist {
		top: 25px;

		&:hover {
			background-color: rgba(var(--primary-color), 1);

			.icon-heart-black {
				color: $white;
			}
		}
	}	
}

.productDetailnew {
	display: flex;
	justify-content: center;
}

.proName {
	font-size: 16px;
	font-weight: 500;
	color: #3a3838;
}

.priceSec {
	font-size: 14px;
	font-weight: 400;
}

.regularprice {
	color: #9a9a9a;
	padding-right: 10px;
	text-decoration: line-through;
}

.saleprice {
	color: rgba(var(--secondary-color), 1);
}

.most-popular-product {
	.swiper-slide {
		padding-left: 10px;
		padding-right: 10px;

		@media all and ( max-width: 767px ) {
			height: auto;
		}

		&:hover {
			box-shadow: none;
		}
	}

	@media all and ( max-width: 992px ) {
		padding-bottom: 0;
	}
}

.home {
	&-product_wrapper {
		.btnWrapper {
			margin-top: 10px;
			padding-bottom: 20px;
		}
	}
}

.singlebtnSection {
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 10px;

	.button {
		&-number {
			width: 30px;
			height: 30px;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				width: 16px;
				height: 16px;
				position: relative;

				&:after,
				&:before {
					position: absolute;
					content: "";
					top: 50%;
					left: 50%;
					transform: translate( -50%, -50% );
				}

				&:before {
					width: 12px;
					height: 2px;
					background-color: $text-color-2;
				}
			}
		}

		&-minus {
			margin-left: 10px;
		}

		&-plus {
			span {
				&:after {
					height: 12px;
					width: 2px;
					background-color: $text-color-2;
				}
			}
		}
	}
}

.singlebtnSectionRight {
	width: 100%;
	margin-top: 20px;

	@media all and ( max-width: 767px ) {
		bottom: 0;
		left: 0;
		right: 0px;
		z-index: 10;
		padding: 10px;
		background-color: rgb(255, 255, 255);
		border-top: 1px solid #e4e3e3;
	}   

	.btn {
		flex: 1;
		width: calc( 50% - 6px );
		margin-right: 5px;
		height: 44px;
		font-size: 16px;
		font-weight: 500;

		+ .btn {
			margin-left: 5px;
			margin-right: 0;
		}
	}
}

.page {
	&-item {
		&.active {
			.page-link {
				background-color: rgba(var(--primary-color), 1);
				border-color: rgba(var(--primary-color), 1);
			}
		}
	}

	&-link {
		color: $black;

		&:focus {
			box-shadow: none;
		}
	}
}


.productDetail {
	.priceSec {
		span {
			display: block;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

.singletitlesection {
	.singleRating {
		.proDistance {
			border: 1px solid #888;
			color: #888;

			@media all and (max-width: 767px ) {
				border: 1px solid rgba(var(--primary-color), 1);
				color: rgba(var(--primary-color), 1);
			}

			a {
				text-decoration: none;
				color: #888;

				@media all and (max-width: 767px ) {
					color: rgba(var(--primary-color), 1);
				}
			}

			&:hover {
				border-color: rgba(var(--primary-color), 1);
				color: rgba(var(--primary-color), 1);

				a {
					text-decoration: none;
					color: rgba(var(--primary-color), 1);
				}
			}
		}
	}

	.verified.small-verified {
		right: -20px;
    	top: 15px;

    	@media all and ( max-width: 991px ) {
    		right: 10px;
    	}
	}

	.icon-heart {
		position: absolute;
		right: 0;

		@media all and ( max-width: 767px ) {
			right: 15px;
		}
	}
}

@media all and ( max-width: 1024px ) {
	.featureProduct_wrapper .featuredProducts:hover .btn-add-cart {
	    opacity: 1;
		margin-bottom: 10px;
	}	
}

#mySinglebtn {
	width: 100%;
}
