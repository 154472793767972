@import "mixins";

.btnWrapper {
    .btn {
        @include padding-l-r( 10px );
        width: auto;
    }
}

// @include media-breakpoint-up ( lg ) {

//banner	
.image_sec {
	img {
		width: 100%;
	}
}

//Ads image
.home-ads {
    position: relative;
    z-index: 1;
    text-align: center;
    // margin-top: -45px;
    margin-bottom: 20px;

    @media all and ( max-width: 767px ) {
        margin-top: 0;
        margin-bottom: 0;
    }

    img {
    	border-radius: 5px;
    	overflow: hidden;
    }
}

.image-gallery-image {
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and ( min-width: 768px ) {
        height: 400px;
    }

    img {
        max-height: 100%;
        width: auto !important;
    }
}
