#{ $all-headings } {
	font-family: $font-family-sans-serif;
	font-family: $font-family-second;
    color: $black;
    font-weight: 700;
}

h2 {
	font-size: 1.5rem;
}
