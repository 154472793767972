@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap");

$font-family-sans-serif: "Nunito", sans-serif;
$font-family-heebo: "Heebo", sans-serif;
$font-family-cabin: 'Cabin', sans-serif;
$font-family-second: 'Lato', sans-serif;

/**
 * Bootstrap variables
 */

$grid-gutter-width: 30px;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
	xxl: 1400px
);

/**
 * Helper variables
 *//**
 * Helper variables
 */

$all-headings:
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6';

$all-text-inputs:
	'input[type="color"]',
	'input[type="date"]',
	'input[type="datetime"]',
	'input[type="datetime-local"]',
	'input[type="email"]',
	'input[type="month"]',
	'input[type="number"]',
	'input[type="password"]',
	'input[type="search"]',
	'input[type="tel"]',
	'input[type="text"]',
	'input[type="time"]',
	'input[type="url"]',
	'input[type="week"]',
	'input:not([type])',
	'textarea',
	'button';

$element-margin: 20px;
$border-radius: 30px;

/**
 * Color variables
 */

$white: #fff;
$off-white: #f6f4f3;
$graywhite: #eee;

$gharBazarGreen: rgba(0,161,57,1);
$gharBazarRed: #e4002b;
$gharBazarBlue: rgba(0,89,159,1);

$black: #000;
$black1: #010101;
$black2: #231F20;


$text-color: #231f20;
$text-color-deam: #848484;
$text-color-2: #282c3f;//#464646;
$text-color-3: #373737;
$text-color-4: #3e4152;

$link-color: #369;
$off-white: #f6f4f3;
$deamwhite: #e8e4e0;

$grey: #eff0f5;
$grey1: #f8f8f8;
$grey2: #ccc;
$bgGrey: #f5f0f0;

$darkblue: #0D2656;
$blue: #1f346b;
$blue1: #009ebf;
$blue2: #008dc2;
$blue-btn: #17a2b8;
$blue-fb: #3B5998;

$orange: #ff6f00;
// $green: #0EB22F;
$green: $gharBazarGreen;
$green1: $gharBazarGreen;
$red: $gharBazarRed;
$red1: $gharBazarRed;
$red2: $gharBazarRed;
$red3: $gharBazarRed;
$dark_red: #a90a24;
$brandRed: #e4002b;
$brandGreen: rgba(0,137,48,1);
$brandBlue: rgba(0,89,159,1);

$gold: #ff6f00;

$primary: $brandBlue;
$secondary: $brandGreen;

$primary-rgb: 6, 91, 141;
$secondary-rgb: 30, 177, 84;

