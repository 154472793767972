@import '../../../assets/styles/variables';

//home featured product
.featuredProducts {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: center;
	padding-bottom: 20px;

	@media all and ( max-width: 767px ) {
		padding-bottom: 0;
	}

	.imgWrapper {
		margin-top: 5px;

		img {
			max-height: 100%;
		}
	}
}

.featureProduct_wrapper {
	.featuredProducts {
		.btn-add-cart {
			max-width: inherit;
			padding: 3px 10px;
		}

		&:hover {
			box-shadow: 0px 0px 12px #ccc;	

			.proDistance {
				border-color: rgba(var(--primary-color), 1);
				color: rgba(var(--primary-color), 1);
			}
		}
	}	
}

.btn.btn-add-cart {
	opacity: 0;
	font-weight: 300;
	padding: 3px;
}

.is-touch-device {
	.btn.btn-add-cart {
		opacity: 1;
	}
}

.pro {
	&Name {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.15;
		margin-bottom: 3px;
		padding-left: 8px;
		padding-right: 8px;
	}

	&Distance {
		background-color: transparent;
		border: 1px solid rgba(0,0,0,0.3);
		color: rgba(0,0,0,0.3);
		display: inline-block;
		padding: 2px 10px 2px 5px;
		border-radius: 20px;
		font-size: 11px;

		@media all and ( max-width: 1024px ) {
			border: 1px solid rgba(var(--primary-color), 1);
			color: rgba(var(--primary-color), 1);
		}

		.icon {
			padding-right: 0;
			// color: rgba(var(--primary-color), 1);
		}
	}
}

.priceSec {
	font-size: 14px;
	font-weight: 300;
}

.regularprice {
	color: #9a9a9a;
	text-decoration: line-through;

	@media all and ( min-width: 768px) {
		display: block;
	}
}

.saleprice {
	color: rgba(var(--secondary-color), 1);
	font-weight: 600;
}

.most-popular-product {
	a.link {
		color: $black;

		&:hover {
			text-decoration: none;
		}
	}

	.featuredProducts {
		.imgWrapper {
			height: 200px;
			margin-bottom: 10px;
			
		}

		.btn-add-cart {
			padding: 3px 10px;
			max-width: inherit;
		}

		.boxed {
			position: relative;
			transition: all 0.3s linear;
		}

		&:hover {
			.btn-add-cart {
				opacity: 1;
			}

			a {
				text-decoration: none;
			}
		}
	}
}

.swiper-slide {
	padding-top: 10px;
	padding-bottom: 10px;
}
