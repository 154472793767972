@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Regular.eot?63904431');
  src: url('../font/Roboto-Regular.eot?63904431#iefix') format('embedded-opentype'),
       url('../font/Roboto-Regular.woff2?63904431') format('woff2'),
       url('../font/Roboto-Regular.woff?63904431') format('woff'),
       url('../font/Roboto-Regular.ttf?63904431') format('truetype'),
       url('../font/Roboto-Regular.svg?63904431#fontello') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Italic.eot?63904431');
    src: url('../font/Roboto-Italic.eot?63904431#iefix') format('embedded-opentype'),
         url('../font/Roboto-Italic.woff2?63904431') format('woff2'),
         url('../font/Roboto-Italic.woff?63904431') format('woff'),
         url('../font/Roboto-Italic.ttf?63904431') format('truetype'),
         url('../font/Roboto-Italic.svg?63904431#fontello') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Light.eot?63904431');
    src: url('../font/Roboto-Light.eot?63904431#iefix') format('embedded-opentype'),
         url('../font/Roboto-Light.woff2?63904431') format('woff2'),
         url('../font/Roboto-Light.woff?63904431') format('woff'),
         url('../font/Roboto-Light.ttf?63904431') format('truetype'),
         url('../font/Roboto-Light.svg?63904431#fontello') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-LightItalic.eot?63904431');
    src: url('../font/Roboto-LightItalic.eot?63904431#iefix') format('embedded-opentype'),
         url('../font/Roboto-LightItalic.woff2?63904431') format('woff2'),
         url('../font/Roboto-LightItalic.woff?63904431') format('woff'),
         url('../font/Roboto-LightItalic.ttf?63904431') format('truetype'),
         url('../font/Roboto-LightItalic.svg?63904431#fontello') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Medium.eot?63904431');
    src: url('../font/Roboto-Medium.eot?63904431#iefix') format('embedded-opentype'),
         url('../font/Roboto-Medium.woff2?63904431') format('woff2'),
         url('../font/Roboto-Medium.woff?63904431') format('woff'),
         url('../font/Roboto-Medium.ttf?63904431') format('truetype'),
         url('../font/Roboto-Medium.svg?63904431#fontello') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Bold.eot?63904431');
    src: url('../font/Roboto-Bold.eot?63904431#iefix') format('embedded-opentype'),
         url('../font/Roboto-Bold.woff2?63904431') format('woff2'),
         url('../font/Roboto-Bold.woff?63904431') format('woff'),
         url('../font/Roboto-Bold.ttf?63904431') format('truetype'),
         url('../font/Roboto-Bold.svg?63904431#fontello') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
